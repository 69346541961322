:root{
    --customTop: 50%;
    --customLeft: 50%;
}
.btn.cbutton--click, .link-feedback.cbutton--click, .link-feedback:focus, .btn:focus {
    outline: none;
}

/* Nikola */
.btn::after, .link-feedback::after {
    background: rgba(111,148,182,0.1);
    position: fixed;
    top: var(--customTop);
    left: var(--customLeft);
    margin: -35px 0 0 -35px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    content: '';
    opacity: 0;
    pointer-events: none;
}

.btn.cbutton--click::after, .link-feedback.cbutton--click::after {
    -webkit-animation: anim-effect-nikola 0.5s forwards;
    animation: anim-effect-nikola 0.5s forwards;
}

@-webkit-keyframes anim-effect-nikola {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.2, 0.2, 1);
        transform: scale3d(0.2, 0.2, 1);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transform: scale3d(1.2, 1.2, 1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
    }
}

@keyframes anim-effect-nikola {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.2, 0.2, 1);
        transform: scale3d(0.2, 0.2, 1);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transform: scale3d(1.2, 1.2, 1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
    }
}
