/* varie homepage e tema generale */
*:focus, .focus {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0) !important;
    border-color: transparent !important;
}

.bg-facebook {
    background: #4267b2;
    color: white;
}

.promo_san_valentino .bg-premium-light {
    background: #c21010 !important;
    color: white;
}

.promo_san_valentino .badge {
    background: #c21010 !important;
    color: white;
}
.icon-heart-rp {
    position: absolute;
    margin-top: -13px;
    right: 5px;
}

.icon-heart-rp img{
     width: 30px;
     transform: rotate(31deg);
     height: 30px;
}

.battery_img {
    max-width: 25px;
    max-height: 25px;
}

.font-size-11 {
    font-size: 11px;
}

.continue_upgrade, .continue_upgrade_mobile {
    height: 61px;
    border-radius: 0.3rem !important;
}

.autoRefill_cont {
    max-width: 500px;
}

.blur_img {
    filter: blur(6px);
    -webkit-filter: blur(6px);
}

.chat {
    min-height: 100%;
}

.chat-body a {
    color: white !important;
}

.min-height-label {
    min-height: 48px;
}

.privacy_cookie {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #eee;
    box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.5);
}

.text_static {
    word-break: break-word;
}

.angle_cam {
    font-size: 54px;
    transform: scale(1.2, 2);
    opacity: 0.4;
    text-shadow: 0px 0px 1px black;
}

.text_static a {
    color: #0000ff !important;
}

.pswp__button--report {
    background: url(../../images/block.png) 0 0 no-repeat !important;
    background-size: 20px 20px !important;
    margin-top: 12px;
    margin-left: 10px;
}

.text_static table {
    width: 100%;
    min-width: 890px;
}

.text_static table td {
    padding: 5px;
}

.cookie_box {
    max-height: 40px;
    overflow: auto;
}

.text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
}

.w-300 {
    width: 300px;
    max-width: 100%;
    overflow: hidden;
}

.do_like.btn-no-focus:focus, .do_like.btn-no-focus:hover {
    box-shadow: inherit !Important;
    border: 1px solid #dee2e6 !important;
}

.btn-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.bottom-right {
    bottom: 10px;
    right: 10px;
    left: auto;
}

.bg_green_light {
    background: #E8F5E9;
}

.rateplan_box *, rateplan_box_mobile * {
    pointer-events: none;
}

.content_rateplan, .content_rateplan_mobile, .tabs_payment {
    max-width: 500px;
}

.text-wrap {
    white-space: normal;
}

.change_favorites {
    cursor: pointer;
}

.w-65 {
    width: 65% !important;
}

.w-55 {
    width: 55% !important;
}

.w-45 {
    width: 45% !important;
}

.w-35 {
    width: 35% !important;
}

.mw-500 {
    max-width: 500px;
}

/* touch feedback */
.touch {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    overflow: hidden;
}

.touch.feedback {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

/* end */

.width-menu {
    width: 63%;
}

.min-w-30 {
    min-width: 30px;
}

.max-width-title {
    max-width: 85%;
}

body.lock-position {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.title_page_box {
    text-overflow: ellipsis;
    overflow: hidden;
}

.menumob {
    max-width: 100%;
}

input:focus, input.focus, select:focus, select.focus, textarea:focus, textarea.focus {
    border-color: #ced4da !important;
}

body {
    background-color: #fcfcfc;
}

.all-container {
    -webkit-overflow-scrolling: touch;
    padding: 60px 0px 105px 0px;
}

.container {
    padding: 0px 0px;
}

.filters-padding {
    padding: 38px 0px 0px 0px;
}

.navbar .container {
    padding: 0px;
}

#homebg {
    background: url(../../images/bg-home.jpeg) no-repeat;
    background-size: cover;
    min-height: calc(100vh - 60px - 0.5rem);
}

h1.claim {
    color: #fff;
}

.bg-black {
    background: #272727;
}

.footer {
    box-shadow: inset 0 13px 15px -7px #000c;
    background: #363636;
    color: white;
    padding: 10px;
    width: 100%;
}

.footer nav a {
    padding: 0px 15px;
    color: white;
}

.footer nav a:hover {
    color: #666;
}

.title_icon_box {
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    color: #d7a860;
}

/* Gestione FONT-SIZE */
.font-size-15 {
    font-size: 15px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-42 {
    font-size: 42px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-72 {
    font-size: 72px;
}

.font-size-100 {
    font-size: 100px;
}

.icon_home_box {
    font-size: 65px;
    text-align: center;
    line-height: 92px;
    color: #d7a860;
}

.d-grid {
    display: grid;
}

#image_right_home {
    max-width: 80%;
    border-radius: 10px;
    margin-top: 50px;
    transform: rotate(7deg);
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.3);
    border: 1px solid white;
    margin-left: 10%;
}

#loader_pagination {
    display: none;
}

#loader_match {
    margin-top: 100px;
}

#loader_pagination i,
#loader_match i {
    font-size: 30px;
    color: #333;
}

.loader_page {
    height: 200px;
}

.loader_page i {
    font-size: 30px;
    color: #333;

}

.flag_icon {
    width: 30px;
}

/* and homepage e tema generale*/

#photo_preview {
    height: 300px;
    width: 300px;
    overflow: hidden;
}

#photo_uploaded {
    max-height: 600px;
    max-width: 600px;
}

/* start popup */
.close_left {
    float: left;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close_right {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.modal-open {
    padding-right: 0px !important;
}

.modal-content {
    border-radius: 0.8rem !important;
}

.max-height {
    max-height: 100%;
}

.modal-backdrop {
    z-index: 1040 !important;
}

.modal-dialog {
    margin: 2px auto;
    z-index: 1100 !important;
}

/* end popup */

/****** start menu ***/

.min-menu {
    min-width: 125px;
    justify-content: flex-end;
    align-items: center;
}

.pr-18p {
    padding-right: 18px;
}

.filters-bar {
    overflow: hidden;
}

.cursorpoint {
    cursor: pointer;
}

.line-height-22 {
    line-height: 2.2rem;
}

.font-icon {
    font-size: 1.5rem;
}

.font-icon-feedback {
    font-size: 3.5rem;
}

.font_icon_feedback {
    font-size: 34px;
    margin: 20px 0px;
}

.font-weight-600 {
    font-weight: 600;
}

.font-09 {
    font-size: 0.9rem;
}

.font-08 {
    font-size: 0.8rem !important;
    line-height: 0.9rem !important;
}

.navbar-brand img {
    max-width: 150px !important;
}

img.img-fluid{
    max-width: 150px !important;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.8);
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    font-size: 16px;
}

.credits_icon {
    background: url(../../images/icon_credits.png) no-repeat;
    padding-left: 23px;
    background-size: 19px;
    background-position: 0px -1px;
}

.title_popup {
    font-size: 1.6rem;
}

.label_menu {
    text-transform: uppercase;
    font-size: 0.8rem;
    display: block;
}

#bottom_menu .badge {
    position: absolute;
    margin-left: -10px;
}

#bottom_menu .nav-link i {
    font-size: 1.5rem;
}

#bottom_menu .nav-link i.custom-icon {
    font-size: 1.4rem !important;
}

#bottom_menu .nav-link {
    overflow: visible;
}

#notificationTab .nav-link {
    padding: 0.5rem 0.8rem;
}

.navbar {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
    outline: none !important;
}

#logo_m {
    max-height: 38px;
}

#collapseOneMob {
    overflow: auto;
    height: calc(100vh - 205px);
    width: 100%;
}

#collapseOneMob.collapsing {
    height: 0;
}

/****** end menu ***/

/****** start Form Search pagina radar ***/
.card-search-page {
    overflow: hidden;
}

@keyframes bounce {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.square {
    width: 50%;
}

.bar_private:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.bar_private {
    position: absolute;
    width: 100%;
    font-size: 22px;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.tit_search {
    background: #ee3557;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    display: block;
    border: 2px solid #ee3557;
    font-size: 1.6rem;
    text-decoration: none;
}

.btn-link.tit_search:hover,
.btn-link.tit_search:focus,
.btn-link.tit_search.focus {
    background: #ee3557;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none;
}

/****** end Form Search pagina radar ***/

.top_menu_img {
    width: 36px;
    height: 36px;
}

.top_menu_img-overlay {
    right: 8px;
    width: 20px;
    top: 32px;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/****** start chat custom ***/

.chat-header {
    height: 68px;
}

.chat-header img {
    width: 45px;
}

.chat-header li {
    list-style: none;
}

.single_chat {
    cursor: pointer;
}

.loader_chat {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 30px;
    z-index: 9;
}

.sending_invisible {
    display: none;
}

.bg-white {
    background-color: #fff;
}

.friend-list {
    list-style: none;
    padding: 0px;
    overflow-y: auto;
    margin-bottom: 0px;
}

.friend-list li {
    border-bottom: 1px solid #eee;
}

.friend-list li .a img {
    float: left;
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.friend-list li .a {
    position: relative;
    display: block;
    padding: 10px;
    height: 100%;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
}

.friend-list li.active .a {
    background-color: #f1f5fc;
}

.friend-list li .a .friend-name,
.friend-list li .a .friend-name:hover {
    color: #777;
}

.friend-list li .a .last-message {
    width: 65%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.friend-list li .a .time {
    position: absolute;
    top: 3px;
    text-align: right;
    right: 8px;
}

.friend-list li .a.more_top .time {
    top: 13px;
}

.friend-list .time p {
    line-height: 12px;
    margin: 0px;
}

small, .small {
    font-size: 85%;
    line-height: 16px;
}

.friend-list li .a .chat-alert {
    position: absolute;
    right: 8px;
    top: 27px;
    font-size: 10px;
    padding: 3px 5px;
}

.chat {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.chat li img {
    width: 45px;
    height: 45px;
    border-radius: 50em;
    -moz-border-radius: 50em;
    -webkit-border-radius: 50em;
}

img {
    max-width: 100%;
}

.chat-body {
    padding-bottom: 20px;
}

.chat li .chat-body {
    position: relative;
    font-size: 13px;
    padding: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    max-width: 75%;
    min-width: 25%;
    border: 1px solid rgba(195, 195, 195, 0.2);
}

.chat li .chat-body .footer_msg {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: right;
    margin-top: 5px;
}

.chat li .chat-body p {
    margin: 0;
}

.chat-body p {
    overflow-wrap: break-word;
}

.chat li {
    margin: 15px 0;
}

.chat li.left .chat-body {
    margin-left: 20px;
    border-radius: 0px 8px 8px 8px;
    color: white;
    float: left;
}

.chat li.right .chat-body {
    margin-right: 20px;
    background-color: #ffecec;
    border-radius: 8px 0px 8px 8px;
    float: right;
}

.chat-box {
    padding-top: 15px;
    padding-bottom: 10px;
}

.primary-font {
    color: #3c8dbc;
}

a:hover, a:active, a:focus {
    text-decoration: none;
    outline: 0;
}

#messageForm {
    padding: 0px 5px;
}

#messageForm .form-group {
    margin-bottom: 0px;
}

#messageForm #message {
    margin: 0px 5px;
}

/****** end chat *****/

/****** start CARD *****/

.card-title .fa-venus {
    color: #ee3557;
}

.card-title .fa-mars {
    color: #1d8bb4;
}

.card-title a {
    color: #212529;
    text-transform: capitalize;
}

.card-subtitle {
    font-style: italic;
}

.card-title a {
    color: #212529;
}

.card-title small {
    font-size: 60%;
}

.max-width-85 {
    max-width: 85%;
    overflow: hidden;
}

.cut-chat {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 60%;
    white-space: nowrap;
}

.cut-username {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
    white-space: nowrap;
}

.cut-city {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    float: left;
}

.puls-resp {
    border-top: 1px solid #d8d8d8;
}

/****** end CARD *****/

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}

/****** start MATCH *****/
.title-match {
    text-align: center;
}

.match-image {
    width: 180px;
    height: 180px;
    border: 5px solid #d3d3d3;
}

.grey {
    background: #e8e8e8;
}

/****** end MATCH *****/

/* encounter */
.user_single .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-height: 400px;
}

#users_container {
    display: none;
    width: 100%;
    max-width: 400px;
}

.user_single {
    position: absolute;
    width: 100%;
    max-width: 400px;
    z-index: 100;
    display: none;
}

.over-all {
    position: absolute !important;
    z-index: 1000 !important;
    width: 100% !important;
    left: 0px !important;
    background-color: #fcfcfc !important;
}

.margin-encounters {
    margin-top: 40px;
}

.active_enc {
    z-index: 1030;
}

.rotate-left {
    transform: rotate(30deg) scale(0.8);
    transition: 1s;
    margin-left: 400px;
    cursor: w-resize;
    opacity: 0;
}

.rotate-right {
    transform: rotate(-30deg) scale(0.8);
    transition: 1s;
    margin-left: -400px;
    cursor: w-resize;
    opacity: 0;
}

.rotate-right::before {
    content: "";
    transform: rotate(-30deg);
}

.rotate-left::before {
    content: "";
    transform: rotate(30deg);
}

.rotate-left .card-img-top {
    transition: .4s;
    opacity: 0;
}

.rotate-right .card-img-top {
    transition: .4s;
    opacity: 0;
}

.enc_buttons_box {
    width: 100%;
}

.enc_buttons {
    font-size: 45px;
    background: transparent;
    border: 0px;
    width: 63px;
    text-align: center;
}

/* end encounter */
/* FILTERS BAR */
.slider {
    width: 100% !important;
}

/* END FILTERS BAR*/

.alert {
    z-index: 99999;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
}

.alert-custom {
    display: none;
}

/****** start FLUSSO DISATTIVAZIONE *****/

.box-disactivate {
    background: #fff;
}

.stato-profilo {
    font-weight: bold;
    font-size: 1.3rem;
}

.list-features-premium .icon-requests_photo, .list-features-premium .icon-matches {
    font-size: 46px;
}

.list-features-premium .icon-requests_photo:before {
    margin: 29px 0px !important;
}

.list-features-premium .icon-matches:before {
    margin: 20px 0px !important;
}

h1.oro {
    color: #e9a23d;
    font-size: 1.8rem;
    text-align: center;
}

#form_disactivation_checkboxes ul {
    list-style: none;
}

#form_disactivation_checkboxes ul span {
    margin-left: 10px;
}

/****** end FLUSSO DISATTIVAZIONE *****/

.slider .tooltip.in {
    opacity: 1;
}

.slider.slider-horizontal {
    width: 80%;
}

#registration_submit_fake {
    visibility: hidden;
    width: 1px;
    height: 1px;
}

.my-location-button .active {
    background-color: #7ac142 !important;
    color: white;
}

.with-private-photo-button .active {
    background-color: #7ac142 !important;
    color: white;
}

#credits_counter {
    visibility: visible !important;
}

#link_abb {
    width: 100%;
    max-width: 500px;
    height: 520px;
    border: none;
    margin: 0 auto;
}

#giftModal .modal-body {
    max-height: 400px;
    overflow-y: scroll;
}

#giftModal .modal-body li {
    cursor: pointer;
}

.material-switch .form-check > input[type="checkbox"] {
    visibility: hidden;
}

.material-switch .form-check > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch .form-check > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position: absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}

.material-switch .form-check > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}

.material-switch .form-check > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}

.material-switch .form-check > input[type="checkbox"]:checked + label::after {
    background: rgb(0, 123, 255);
    left: 20px;
}

/* end flusso disattivazione / impostazioni */

.form_registration label, .form-login label {
    font-size: 0.9rem;
}

.form_registration_facebook {
    display: none;
}

/* page notifiche */

.label_premium img {
    height: 100%;
    position: absolute;
    right: 0px;
}

.label_premium {
    height: 100%;
}

.nav-tabs .nav-link {
    border-color: #dedede;
}

.nav-tabs i {
    font-size: 28px;
    line-height: 28px;
}

.icon-requests_photo:before {
    margin: 0px;
    line-height: 28px;
}

.nav-tabs {
    border-bottom: 0px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border: 0px;
    background: transparent !important;
}

.nav-tabs .nav-link.active {
    color: #212529;
}

.nav-tabs .badge-danger {
    color: #fff;
    position: absolute;
    background-color: #dc3545;
    margin-top: -30px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 15px;
    margin-top: 19px;
    margin-left: 15px;
}

.notif_cont_img {
    max-height: 100%;
    border-radius: 50%;
    width: 58px;
    margin: auto;
    overflow: hidden;
    min-width: 58px;
}

.notif_username {
    max-width: 90%;
}

.notif-img-top {
    width: 58px;
    height: 58px;
    border-radius: 50%;
}

.notif {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.notif_icon_close {
    position: absolute;
    right: 0.50rem;
    top: 0.4rem;
    cursor: pointer;
}

.feedback_empty_content {
    padding: 20px;
}

/* pagina foto */

.photo_actions {
    justify-content: space-around;
    display: flex;
}

.list-group-item {
    background: transparent;
    line-height: 14px;
    padding: 18px 0px;
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item-custom {
    background: transparent;
    line-height: 14px;
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    list-style: none;
}

.list-group-item-custom a {
    padding: 18px 0px;
    height: 100%;
    width: 100%;
}

.fos_user_user_show .list-group-item.text {
    line-height: 18px;
}

.custom-modal {
    position: fixed;
    top: -300px;
    width: 90%;
    max-width: 400px;
    left: 5%;
    z-index: 999999;
    background: white;
    transition: 500ms;
    margin: 0 auto;
}

.show-custom-modal {
    top: 100px;
}

.delivery-status {
    font-size: 18px;
}

.delivery-status .read {
    color: #14aaf5;
}

.back-img-women {
    background: url("../../images/ask_private_photos.jpg");
    background-repeat: no-repeat;
    background-position: center;
}

/* end profilo */
/* media */

.bg_sanvalentino {
    background-image: url("../../images/bg_sanvalentino.jpg");
    background-position: center center;
}

.bg_festadonna {
    background-image: url("../../images/bg_festadonna.jpg");
    background-position: center 18%;
}

.bg_sanvalentino, .bg_festadonna {
    background-size: cover;
    min-height: 350px;
}

.bg_sanvalentino h4, .bg_festadonna h4 {
    color: white;
    font-size: 3rem;
    margin: 0px;
}

.bg_uxmessage {
    background-image: url("../../images/bg_default_chat_ux.jpg");
    background-position: center 18%;
}

.chat-message {
    overflow-y: auto;
    height: calc(100vh - 120px);
}

@media (min-width: 990px) {
    .chat-message {
        height: calc(100vh - 304px);
    }

    .chat {
        overflow-y: auto;
    }

    .all-container {
        padding: 75px 0px 10px 0px;
        min-height: calc(100vh - 105px);
    }

    .modal-dialog-centered-md {
        margin-top: 9rem;
    }
}

@media (max-width: 989px) {

    .bg_sanvalentino, .bg_festadonna {
        min-height: 240px;
    }

    .bg_sanvalentino h4, .bg_festadonna h4 {
        font-size: 2rem;
    }

    .loader_chat {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 30px;
        z-index: 9;
    }

    .backdrop {
        padding-top: 40px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: calc(100vh - 60px - 0.5rem);
        position: absolute;
    }

    #homebg_7 .backdrop{
        position: static !important;
    }

    .w-sm-300 {
        width: 300px;
    }

    .chat {
        padding-bottom: 25%;
    }

    .chat-box {
        width: 100%;
        position: fixed;
        bottom: 0px;
    }

    .modal-dialog-centered-md {
        display: flex;
        align-items: center;
        min-height: calc(100% - (1.75rem * 2));
    }
}

@media (max-width: 1023px) {
    #card-search-page .card-body h4 {
        font-size: 1rem;
    }

    #card-search-page .p-resp {
        padding: 0 5px;
    }

    /* #card-search-page .puls-resp, */
    #card-search-page .card-footer {
        display: none;
    }

    #card-search-page .alert {
        margin-bottom: 0;
    }

    #matchModal .w-50 {
        width: 100% !important;
    }

    .title-match .display-3 {
        font-size: 1.5rem;
        line-height: 0.5
    }

    .title-match .display-2 {
        font-size: 3.5rem;
        line-height: 1.5
    }

    .match-image {
        width: 120px;
        height: 120px;
    }

    #matchModal .btn-lg, .btn-group-lg > .btn {
        font-size: 1rem;
    }

    #matchModal .modal-footer.mt-4 {
        margin-top: 0.5rem !important;
    }

    #matchModal .modal-body {
        padding-top: 0;
    }

    .menu-user-profile .ml-3 {
        margin-left: 0 !important;
    }
}

@media (max-width: 1199px) {
    .card-title a {
        font-size: 0.9rem;
    }
}

@media (min-width: 768px) {

    .custom-modal {
        top: inherit;
        bottom: -300px;
        right: 100px;
        left: inherit;
    }

    .show-custom-modal {
        bottom: 50px;
    }

    #claim_hp {
        font-size: 48px;
    }

    .bg-md-white {
        background: white;
    }

    .shadow-md {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .notif {
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.125);
        transition: background-color 300ms;
    }

    .notif:hover {
        background: white;
    }

    .notif_limit_box {
        cursor: pointer;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    }
}

@media (min-height: 735px) {
    .padding-to-bottom {
        padding-top: 28vh;
    }
}

@media (min-width: 991px) {

    .padding-to-bottom {
        padding-top: 43vh;
    }

    #claim_hp {
        font-size: 74px;
    }

    .filters-no-padding {
        margin-top: 30px;
    }

    .friend-list {
        max-height: calc(70vh - 49px);
        overflow: auto;
    }

    .px-lg-10 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (max-width: 768px) {

    .footer nav a {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding: 12px 0px;

    }

    .back-img-women {
        background-size: cover;
    }
}

.fb-login-button span {
    max-width: 100% !important;
    overflow: hidden !important;
    width: 300px !important;
    border-radius: 0.25rem;
}

.gift-images {
    width: 95px;
    margin: 0px !important;
}

.text-underline {
    text-decoration: underline;
}

#change_email_link {
    text-decoration: underline;
    cursor: pointer;
}

ul.thumbnails.image_picker_selector li .thumbnail {
    width: 95px !important;
    height: 95px !important;
    border: 0px !important;
}

ul.thumbnails.image_picker_selector li .thumbnail img {
    width: 95px !important;
}

.bg-gray {
    background: #eee;
}

.img-95 {
    width: 95px;
}

[data-toggle~="collapse"] {
    cursor: pointer;
}

ins img {
    height: auto;

}

.icon-credit-left {
    width: 32px;
}

.icon-credit-right {
    width: 32px;
}

.creditsModal .buyCreditsButton, .rateplan_box, .rateplan_box_mobile, .camQuitModal {
    min-height: 50px !important;
}

.overlay-photo-profile {
    bottom: 3%;
    left: 30%;
    z-index: 12;
}

.overlay-photo {
    bottom: 14%;
    left: 24%;
    font-size: 11pt;
}

.overlay-moder {
    bottom: 18%;
    left: 3%;
    width: 94%;
}

.overlay-moder-small {
    bottom: 6%;
    left: 18%;
    width: 64%;
}

.overlay-moder-profile {
    bottom: 5%;
    left: 0%;
    width: 100%;
}

.image_popup_header {
    border-radius: 50%;
    position: relative;
    border: 3px solid #eee;
}

.image_popup_header img {
    border-radius: 50%;
}

.custom-badge {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 21px;
    bottom: 0;
    margin-left: -35px;
}

@media (max-width: 480px) {
    .overlay-photo {
        bottom: 18% !important;
        left: 27% !important;
        font-size: 0.5rem !important;
    }

    .overlay-photo-profile {
        bottom: 3% !important;
        font-size: 1rem !important;
    }
}

@media (max-width: 610px) and (min-width: 480px) {

    .overlay-photo-profile {
        bottom: 4% !important;
        font-size: 1.1rem;
        left: 25%;
    }

}

@media (max-width: 767px) {
    .overlay-photo {
        bottom: 22%;
        left: 29%;
        font-size: 12pt;
    }

    .overlay-moder {
        bottom: 24%;
        left: 4%;
        width: 92%;
    }

    .overlay-photo-profile {
        bottom: 5%;
        font-size: 1.6rem;
    }

}

.overlay-photo-profile-small {
    font-size: 6pt;
    bottom: -4%;
    z-index: 12;
    left: 32%;
}

.banner-block-mod {
    position: absolute;
    width: 100%;
    margin-top: -50px;
    margin-bottom: 20px;
}

.triangle-block-mod {
    font-size: 12px;
    margin-top: 4px;
    margin-left: 3px;
    position: absolute;
}

.cont-triangle-block-mod {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    margin-top: -13px;
    margin-left: 20px;
}

.fa-flip-rotate {
    transform: rotate(90deg) scaleX(-1);
}

.tab_pay {
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.tab_pay.active {
    border-bottom: 3px solid #7ac142;
}

.operator.border-success {
    border: 2px solid;
}

.operator {
    border: 1px solid #eee;
}

.card-body {
    padding: 0.30rem;
}

#online_status {
    position: fixed;
    top: 60px;
    width: 90%;
    margin: 0px 5%;
    display: none;
}

.shake_animation {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

#RegistrationModal label.required:after {
    content: " *";
    color: #b72424;
}

#link-privacy {
    color: rgb(0, 0, 238) !important;
}

.ot-sdk-show-settings {
    border: none;
}

#onetrust-banner-sdk #onetrust-close-btn-container {
    top: 15% !important;
}

/* UX Messages */
.img-background {
    position: relative;
    text-align: center;
    color: white;
    border-radius: 0.3rem !important;
}

.radius {
    border-radius: 0.3rem !important;
}

.text-black {
    color: black !important;
}

.text-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.uxmessage_icon {
    background: url(../../images/popup_images/chat.png) no-repeat;
    padding-left: 23px;
    background-size: 19px;
    background-position: 0px -1px;
}

.border-selected_rateplan {
    border: 2px solid #7ac142 !important;
}

.bg-left-credits-uxmessage {
    background: #fff !important;
}

.bg-right-credits-uxmessage {
    background: #ededed !important;
}

.bg-uxmessage {
    background: #9e1a45;
}

.border-uxmessage {
    border-color: #9e1a45 !important;
}

.text-color-uxmessage {
    color: #9e1a45;
}

.text-color-uxmessage_possibility {
    color: #ddbb00;
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}

.margin-nen {
    margin-top: -20px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-22 {
    font-size: 22px;
}

.height-200 {
    max-height: 200px;
}

.text_compliant {
    font-size: 13px !important;
}

/* rateplan template 4 */

.rateplan_box_style_4 {
    background: #dff0de;
    border: 0 !important;
    color: #626763;
    border-top: 1px solid !important;
    border-color: #d2d2d2 !important;
}

.rateplan_box_style_4.border-selected_rateplan {
    border-top: 0 !important;
    border-color: #d2d2d2 !important;
}

.rateplan_box_style_4 .span_gray {
    color: #6c726d;
    font-size: 28px;
    line-height: 28px;
    margin-right: 10px;
    font-weight: 700 !important;
}

.rateplan_box_style_4.border-selected_rateplan .span_gray {
    color: #49b14b;
}

.rateplan_box_1 .rateplan_box_style_4, .content_rateplan .rateplan_box_style_4 {
    border-radius: 0;
}

.rateplan_box_1 .rateplan_box_style_4:nth-child(1), .content_rateplan .rateplan_box_style_4:nth-child(1) {
    border-radius: 20px 20px 0 0 !important;
    border-top: 0 !important;
}

.rateplan_box_1 .rateplan_box_style_4:nth-last-of-type(1), .content_rateplan .rateplan_box_style_4:nth-last-of-type(1) {
    border-radius: 0 0 20px 20px !important;
}

.rateplan_box_style_4.border-selected_rateplan {
    box-shadow: 0 0 2px 2px #cbcbcb;
    background: white !important;
    z-index: 999 !important;
    position: relative;
}

.rateplan_box_style_4.border-selected_rateplan .zoom_text_selected {
    color: #49b14b;
}

.rateplan_box_style_4 .zoom_text_selected {
    font-weight: 600;
}

.rateplan_box_style_4.border-selected_rateplan .left_button .zoom_text_selected {
    border-right: 1px solid #dee2e6;
}

.rateplan_box_style_4.border-selected_rateplan .right_button .zoom_text_selected {
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
}

.rateplan_box_style_4 .w-130p {
    width: 130px;

}

.style_template_4 .img-background img {
    border-radius: 15px !important;
    box-shadow: 10px 10px 13px -11px #464646;
    margin-bottom: 20px;
}

.style_template_4 .text-line1 {
    font-weight: 700;
    font-size: 1.6rem;
    color: #49b14b;
}

.style_template_4 .text-line2 {
    color: #626763;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    font-style: italic;
}

@media (min-width: 1024px) {
    .style_template_4 #imgchat_web {
        max-width: 35%;
    }
}

/* end rateplan template 4 */

/* popup go to app*/
#go_to_app_popup {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    width: 100%;
    height: 100%;
}

#go_to_app_popup_container {
    background: white;
    width: 1134px;
    height: 697px;
    border-radius: 39px;
    overflow: hidden;
}

#go_to_app_left {
    background-image: url("../../images/go-to-app-popup-left.png");
    background-repeat: no-repeat;
    height: 100%;
    width: 484px;
    position: relative;
}

#go_to_app_right {
    background: white;
    width: 650px;
    padding: 50px;
}

#go_to_app_popup .icon_logo {
    width: 86px;
    height: 86px;
}

#go_to_app_footer_left {
    position: absolute;
    width: 92%;
    bottom: 30px;
    line-height: 30px;
    padding: 0 35px;
    font-weight: bold;
    color: white;
}

.label-go-to-app {
    width: 300px;
}

#go_to_app_cont {
    margin-left: 50px;
}

.go_to_app_content {
    font-size: 20px;
    padding: 3rem 60px !important;
}

#go_to_app_title {
    font-size: 34px;
    font-weight: 100;
}

#go_to_app_popup .go_to_app_content p {
    margin-bottom: 35px;
}

#go_to_app_footer_left img {
    height: 30px;
}

#go_to_app_popup .go_to_app_content a {
    color: #006DAA;
    text-decoration: underline;
}

.btn-go-to-app {
    padding: 10px 40px;
    background: #B70050;
    color: white;
    font-weight: 700;
    border-radius: 70px;
    font-size: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 40px;
    min-width: 245px;
}

.btn-go-to-subscriptions {
    padding: 10px 50px;
    border: 1px solid #B70050;
    background: white;
    color: #B70050;
    overflow-wrap: break-word;
    border-radius: 70px;
    max-width: 245px;
    font-size: 14px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.h-30px {
    height: 30px;
    margin-bottom: 0;
}

@media (max-width: 1060px) {

    #go_to_app_cont {
        margin-left: 0;
        width: 100%;
        padding: 10px;
    }

    .go_to_app_buttons {
        padding: 0 10px !important;
    }

    #go_to_app_right .btn {
        width: 100%;
        margin-bottom: 10px;
    }

    #go_to_app_right .icon_logo {
        width: 100%;
        height: 56px;
    }

    #go_to_app_right .icon_logo img {
        width: 56px;
        height: 56px;
    }

    #go_to_app_left {
        display: none;
    }

    #go_to_app_popup_container {
        width: 90%;
        height: auto;
    }

    #go_to_app_right {
        width: 100%;
        padding: 30px;
    }

    .go_to_app_content {
        padding: 0 !important;
        font-size: 1rem;
        margin: 20px 0;
    }

    #go_to_app_title {
        font-size: 25px;
    }

    .btn-go-to-subscriptions {
        padding: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;

    }

}

/* end popup go to app */

/* start hometemplate3 */

#hometemplate3 #claim_hp {
    font-size: 50px;
    color: #292929;
}

#hometemplate3 .form_registration_container {
    /*background: white;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 20px 30px -10px rgb(0 0 0 / 50%);
    border-top: 25px solid #cc2e90;*/

    background: white;
    border-radius: 25px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    left: 50%;
    width: calc(50% - 20px);
    max-width: 550px;
    box-shadow: 0px 20px 30px -10px rgb(0 0 0 / 50%);
    border-top: 25px solid #cc2e90;
    margin-left: 20px;
}

#hometemplate3 #homebg {
    min-height: 720px;
}

@media (max-width: 768px) {
    #hometemplate3 .form_registration_container {
        width: 90%;
        position: static;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    #hometemplate3 #homebg {
        min-height: auto;
        padding-bottom: 30px;
        background-position: 37% 100% !important;
    }

    #hometemplate3 .text_under_form {
        width: 90%;
        background: white;
        border-radius: 25px;
        margin-top: 250px;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        box-shadow: 0px 10px 10px -5px rgb(0 0 0 / 50%);
        text-align: center;
    }

    #hometemplate3 .text_under_form p {
        margin-bottom: 0;
    }

}

/* end hometemplate3 */


/* start hometemplate3 */

#hometemplate5 #claim_hp {
    font-size: 50px;
    color: #292929;
}

#hometemplate5 .form_registration_container {
    background: white;
    border-radius: 25px;
    margin-left: auto;
    max-width: 480px;
    box-shadow: 0px 20px 30px -10px rgb(0 0 0 / 50%);
    border-top: 25px solid #FFB68E;
}

#hometemplate5 #homebg {
min-height: 1100px;
background: none !important;
}

@media (max-width: 768px) {
#hometemplate5 .form_registration_container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

#hometemplate5 #homebg {
    min-height: auto;
    padding-bottom: 30px;
}

#hometemplate5 .text_under_form {
    width: 90%;
    background: white;
    border-radius: 25px;
    margin-top: 250px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    box-shadow: 0px 10px 10px -5px rgb(0 0 0 / 50%);
    text-align: center;
}

#hometemplate5 .text_under_form p {
    margin-bottom: 0;
}

}

/* end hometemplate 5 */

.cam-custom-modal {
    position: absolute;
    bottom: 0;
    width: -moz-available;
    background: transparent;
}

.pulsating-circle {
    position: relative;
    width: 18px;
    margin-right: 10px;
    height: 18px;
    color: #dc3545 !important;
}

.pulsating-circle:before {
    content: '';
    position: relative;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    border-radius: 45px;
    background-color: #dd4444;
    animation: pulse-ring 1.50s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: red;
    border-radius: 15px;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    animation: pulse-dot 1.50s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

@keyframes pulse-ring {
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.8);
    }
}

input[type="search"] {
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.eye-icon-pass {
    cursor: pointer;
    margin-top: -27px;
    float: right;
    margin-right: 10px;
    display: block;
}

/* Gestione Errori Bank */
.titleError {
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #EE6A6A;
}

.descriptionError {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #333333;
}

.descriptionError span {
    color: #4CB050;
    text-transform: uppercase;
}

.ctaError {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #fff;
}

.ctaError span {
    font-weight: 500;
    font-size: 12px;
}

.btnError {
    background: linear-gradient(180deg, #4BCA50 0%, #17651A 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 54px;
    border: none;
}

/* popup conferma o cambia email */
.logo-small {
    max-height: 50px;
    max-width: 50%;
    margin-bottom: 14px;
}

#confirm-email-img-popup{
    max-width: 114px;
    margin-bottom: 10px;
}

.icon-svg-primary {
    width: 30px;
    max-height: 30px;
}

.icon-svg-big {
    width: 48px;
    max-height: 48px;
}

.svg-hearth {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
}

.svg-mail-settings {
    shape-rendering: geometricPrecision;
    text-rendering: geometricPrecision;
    image-rendering: optimizeQuality;
    fill-rule: evenodd;
    clip-rule: evenodd;
}

.icon-svg-open{
    width: 54px;
    max-height: 48px;
}

.icon-svg-checks{
    height: 24px;
    width: 41px;
    fill: #7ac142 !important;
}

.border-x{
    border-left: 1px solid #DADADA;
    border-right: 1px solid #DADADA;
}

.link-blue{
    color: #063797;
    text-decoration: underline;
}

.text-blue{
    color: #063797;
}

.first-item-profile{
    padding: 12px 0 !important;
}

#icon-edit-email{
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.3);
    border-radius: 50% !important;
    background: #a8c9e6 !important;
    padding: 7px !important;
    font-size: 14px !important;
    margin-left: 10px;
    cursor: pointer;
}

#confirmOrChangeEmail .modal-content{
    width: 94%;
    margin: auto;
    background: rgba(255,255,255,0.9) !important;
}

#confirmOrChangeEmail h3{
    font-size: 1.5em;
    font-weight: 600;
}

#confirmOrChangeEmail .box-icons{
    font-size: 14px;
}

/* start homeTemplate6 */
#hometemplate6 #homebg {
    background: #fff;
    min-height: 720px;
}
#hometemplate6 .form_registration_container {width: 100%;}

@media (max-width: 768px) {
    #hometemplate6 .form_registration_container {
        width: 90%;
        position: static;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        overflow: hidden;
    }

    #hometemplate6 #homebg {
        min-height: auto;
        padding-bottom: 30px;
        background-position: 37% 100% !important;
    }

    #hometemplate6 .text_under_form {
        width: 90%;
        background: white;
        border-radius: 25px;
        margin-top: 250px;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        box-shadow: 0px 10px 10px -5px rgb(0 0 0 / 50%);
        text-align: center;
    }

    #hometemplate6 .text_under_form p {
        margin-bottom: 0;
    }

}
/* end homeTemplate6 */

img.img-fluid-hometemplate7{max-width: 300px !important;}